.App {
  font-family: math;
  text-align: left;
  background-color: black;
}

.contact-us-form {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
}

.contact-us-form form {
  display: flex;
  flex-direction: column;
}

.contact-us-form label {
  margin-bottom: 10px;
}

.contact-us-form input,
.contact-us-form textarea {
  margin-top: 5px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.contact-us-form button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: blue;
  color: white;
  cursor: pointer;
}

.site-footer {
  background-color: #333;
  color: rgb(10, 10, 10);
  padding: 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  align-items: left;
}

.contact-us h3,
.social-media-links h3 {
  margin-bottom: 10px;
}

.contact-us p {
  margin: 5px 0;
}

.social-media-links a {
  display: block;
  color: white;
  margin: 5px 0;
  text-decoration: none;
}

.social-media-links a:hover {
  text-decoration: underline;
}

